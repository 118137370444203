<template>

	<div style="width: 100%;height: 100%;">
		<el-tabs v-model="activeName" type="card" @tab-click="changeCourse" style="width: 100%">
			<el-tab-pane :label="item.course_name" :name="item.id+''" v-for="(item,index) in CourseList">

			</el-tab-pane>
		</el-tabs>

		<div style="padding: 30px;" v-if="dataview">
			<div class="smlbox" v-if="dataview.resultTotal">
				<div style="padding: 20px 0 0 20px; font-size: 30px;">{{dataview.resultTotal.total}}</div>
				<div style="padding-left: 20px;">总练习数</div>
			</div>
			<div class="smlbox" v-if="dataview.resultTotal">
				<div style="padding: 20px 0 0 20px; font-size: 30px;">
					{{parseInt(dataview.resultTotal.correct/dataview.resultTotal.total*10000)/100}}%
				</div>
				<div style="padding-left: 20px;">总正确率</div>
			</div>
			<div class="smlbox" v-if="dataview.resultToday">
				<div style="padding: 20px 0 0 20px; font-size: 30px;">{{dataview.resultToday.total}}</div>
				<div style="padding-left: 20px;">今日总练习数</div>
			</div>
			<div class="smlbox" v-if="dataview.resultToday">
				<div style="padding: 20px 0 0 20px; font-size: 30px;">
					{{parseInt(dataview.resultToday.correct/(dataview.resultToday.total>0?dataview.resultToday.total:1)*10000)/100}}%
				</div>
				<div style="padding-left: 20px;">今日正确率</div>
			</div>
			<div class="smlbox">
				<div style="padding: 20px 0 0 20px; font-size: 30px;">{{dataview.joinExam?dataview.joinExam:0}}</div>
				<div style="padding-left: 20px;">参与考试人数</div>
			</div>

		</div>
		<div style="padding: 30px;">
			<div id="totalpie" class="chartitem"></div>
			<div id="todaypie" class="chartitem" style="margin-left: 1%;"></div>
		</div>
		<div style="padding:10px 30px;">
			<div id="tmlist" class="tmlist"></div>

		</div>
	</div>

</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				vtype: "",
				isAdmin: false,
				activeName: "0",
				CourseList: [{
					id: "0",
					course_name: "全部课程"
				}],
				dataview: null
			}
		},
		mounted() {
			let user = sessionStorage.getItem("userinfo");
			if (user) {
				user = JSON.parse(user)
				
				this.isAdmin = true
			}
			if (this.isAdmin) {
				this.getTeacherCourse()
			} else {
				this.getData()
			}


		},
		methods: {
			changeCourse(){
					this.getData()
			},
			getTeacherCourse() {
				this.$http.post("/api/t_sch_course_list", {
					pagesize: 100
				}).then(res => {
					this.CourseList = res.data.data
					this.activeName = this.CourseList[0].id
					this.getData()
				})
			},
			getData() {
				this.$http.post("/api/get_all_zuoti_num", {
					course_id: parseInt(this.activeName) 
				}).then(res => {
					let dataview = {}
					dataview.resultToday = res.data.resultToday
					dataview.resultTotal = res.data.resultTotal
					dataview.joinExam = res.data.joinExam
					dataview.errorTm = res.data.errorTm
					this.dataview = dataview
					this.initTotalPie()
					this.initTodayPie()
					this.initErrTM()
				})
			},
			initTotalPie() {
				let colors = [
					'rgb(1,231,225)',
					'rgb(229,88,234)'

				];
				if (this.dataview.resultTotal.total == 0) {
					this.dataview.resultTotal.total = 1
				}
				let ok = parseInt(this.dataview.resultTotal.correct / this.dataview.resultTotal.total * 10000) / 100
				let fail = 100 - ok

				let option = {
					tooltip: {
						trigger: 'item',
						formatter: function(params) {

							return params.data.name + ":" + params.data.value + "%"
						}
					},
					title: {
						text: '历史总做题正确率统计',
						left: 'left'
					},
					legend: {
						top: '5%',
						left: 'center',
					},
					color: colors,
					series: [{
						name: '题目',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: ok,
								name: '正确率'
							},
							{
								value: fail,
								name: '错误率'
							},

						]
					}]
				};

				let myChart = this.$echarts.init(document.getElementById('totalpie'))


				myChart.setOption(option)

			},
			initTodayPie() {
				let colors = [
					'#3660ff',
					'#fd6e95'

				];
				if (this.dataview.resultToday.total == 0) {
					this.dataview.resultToday.total = 1
				}
				let fail = parseInt(this.dataview.resultToday.error / this.dataview.resultToday.total * 10000) / 100
				let ok = 100 - fail

				let option = {
					tooltip: {
						trigger: 'item',
						formatter: function(params) {

							return params.data.name + ":" + params.data.value + "%"
						}
					},
					title: {
						text: '今日做题正确率统计',
						left: 'left'
					},
					legend: {
						top: '5%',
						left: 'center',
					},
					color: colors,
					series: [{
						name: '题目',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{
								value: ok,
								name: '正确率'
							},
							{
								value: fail,
								name: '错误率'
							},

						]
					}]
				};

				let myChart = this.$echarts.init(document.getElementById('todaypie'))


				myChart.setOption(option)

			},
			initErrTM() {
				let colors = [
					'rgb(1,231,225)',
					'rgb(229,88,234)',
					'rgb(160,81,235)',
					'rgb(42,178,116)',
					'rgb(233,156,187)',
					'rgb(241,213,100)',
					'rgb(116,216,243)',
					'rgb(55,182,255)',
					'rgb(1,231,225)',
					'rgb(229,88,234)',
					'rgb(160,81,235)',
					'rgb(42,178,116)',
					'rgb(233,156,187)',
					'rgb(241,213,100)',
					'rgb(116,216,243)',
					'rgb(55,182,255)',
					'rgb(1,231,225)',
					'rgb(229,88,234)',
					'rgb(160,81,235)',
					'rgb(42,178,116)',
					'rgb(233,156,187)',
					'rgb(241,213,100)',
					'rgb(116,216,243)',
					'rgb(55,182,255)',
					'rgb(1,231,225)',
					'rgb(229,88,234)',
					'rgb(160,81,235)',
					'rgb(42,178,116)',
					'rgb(233,156,187)',
					'rgb(241,213,100)',
					'rgb(116,216,243)',
					'rgb(55,182,255)',
					'rgb(1,231,225)',
					'rgb(229,88,234)',
					'rgb(160,81,235)',
					'rgb(42,178,116)',
					'rgb(233,156,187)',
					'rgb(241,213,100)',
					'rgb(116,216,243)',
					'rgb(55,182,255)',
				];



				let option = {
					title: {
						text: '错误率最高题目Top20',
						left: 'left'
					},
					tooltip: {
						trigger: 'item',
						formatter: function(params) {
					
							return  "错误次数:" + params.data.value + "次"
						}
					},
					grid: {
						left: '10%',
						bottom: '50%'
					},
					xAxis: {
						type: 'category',
						data: this.dataview.errorTm.map((e, idx) => {
							return e.qst_title
						}),
						axisLabel: {
							interval: 0,
							rotate: 80
						},
					},
					yAxis: {
						type: 'value'
					},

					series: [{
						data: this.dataview.errorTm.map((e, idx) => {
							return {
								value: e.err_count,
								itemStyle: {
									color: colors[idx]
								}
							}
						}),


						type: 'bar'
					}]
				};

				let myChart = this.$echarts.init(document.getElementById('tmlist'))


				myChart.setOption(option)

			}
		},
	}
</script>

<style scoped="scoped" type="text/css">
	.smlbox {
		width: 18%;
		height: 100px;
		display: inline-block;
		vertical-align: top;
		margin-right: 2%;
		border-radius: 6px;
		color: #fff;
	}

	.smlbox:nth-child(1) {
		background: -webkit-linear-gradient(left, #1cbddd, #5ecce0)
	}

	.smlbox:nth-child(2) {
		background: -webkit-linear-gradient(left, #fd6e95, #fda69e)
	}

	.smlbox:nth-child(3) {
		background: -webkit-linear-gradient(left, #3660ff, #7284ff)
	}

	.smlbox:nth-child(4) {
		background: -webkit-linear-gradient(left, #f3b31e, #f7d180)
	}

	.smlbox:nth-child(5) {
		background: -webkit-linear-gradient(left, #463671, #7d5eba)
	}

	.chartitem {
		width: 49%;
		height: 300px;
		display: inline-block;
		border: 1px solid #ccc;
		border-radius: 6px;
		vertical-align: top;
	}

	.tmlist {
		height: 800px;

		border: 1px solid #ccc;
		border-radius: 6px;
		vertical-align: top;
	}
</style>
